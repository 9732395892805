import React, { useState } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Pill from '@ingka/pill';
import document from '@ingka/ssr-icon/paths/document';
import arrowDownToBase from '@ingka/ssr-icon/paths/arrow-down-to-base';
import chevronRightSmall from '@ingka/ssr-icon/paths/chevron-right-small';
import chevronLeftSmall from '@ingka/ssr-icon/paths/chevron-left-small';
import './tableLayout.scss';
import Status from '@ingka/status';
import { getFEDSAReferenceNumber, statusCheck } from 'utils/common';
import Search from '@ingka/search';
import Tooltip from '@ingka/tooltip';
import { useDispatch } from 'react-redux';
import { downloadDDSExcel, downloadDDSPdf } from 'Action/pendingApprovalAction';
import { AppDispatch } from 'Store/store';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import { ModalData } from 'components/Modal/modalData';
import Products from 'pages/Products/products';
import SSRIcon from '@ingka/ssr-icon';
import Pagination from 'components/Pagination/pagination';

const TableLayout = (props: any) => {
  const {
    items,
    isLeftSide,
    pendingApprovalData,
    selectedRow,
    onSearch,
    onShowDetails,
    ddsReference,
  } = props;
  const headers = items && items.length > 0 ? Object.keys(items[0]) : [];
  const capitalizeHeader = (header) =>
    header
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  const MAX_ARTICLES_LENGTH = 20;
  const ITEMS_PER_PAGE = 7;
  const [highlightedRow, setHighlightedRow] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const downloadPdf = (e: any, header: any, item: any, type: any) => {
    e.stopPropagation();
    let id = getFEDSAReferenceNumber(pendingApprovalData, ddsReference || item.DDS_Ref_Num);
    if (type === 'pdf') {
      dispatch(downloadDDSPdf(id));
    } else {
      dispatch(downloadDDSExcel(id));
    }
  };

  const status = (products: any) => {
    let stockAvailablity = statusCheck(products);
    return (
      <Status
        statusColor={stockAvailablity.color as any}
        statusDotPosition="leading"
        small={false}
        onClick={stockAvailablity.color === 'green' ? () => {} : () => onClickModal(products)}
      />
    );
  };
  const glaStatus = (item, header) => {
    if (item[header]?.passedCount) {
      return item[header]?.passedCount;
    } else if (item[header]?.failedCount) {
      return item[header]?.failedCount;
    } else {
      return (
        <Status
          label="Awaiting GLA result from ESRI"
          statusColor="grey"
          statusDotPosition="leading"
        />
      );
    }
  };
  const onClickModal = (products) => {
    setModalDetails(products);
    setModalOpen(true);
  };
  const renderTableDetails = (header, item) => {
    if (header === 'PDF') {
      return (
        <Pill
          size="xsmall"
          onClick={(e) => downloadPdf(e, header, item, 'pdf')}
          ssrIcon={arrowDownToBase}
        />
      );
    } else if (header === 'CRA') {
      return <div className="tooltip-demo__container">{status(item)}</div>;
    } else if (header === 'GLA') {
      return (
        <span
          style={{ color: item?.CRA?.isPassed === false ? 'red' : 'green', cursor: 'pointer' }}
          onClick={(e) =>
            item[header]?.passedCount ? downloadPdf(e, header, item, 'excel') : () => {}
          }
        >
          {glaStatus(item, header)}
        </span>
      );
    } else if (header === 'articles') {
      const articlesText = item[header];
      const showTooltip = articlesText.length > MAX_ARTICLES_LENGTH;
      const displayText = showTooltip
        ? `${articlesText.slice(0, MAX_ARTICLES_LENGTH)}...`
        : articlesText;
      return showTooltip ? (
        <Tooltip describedById="tooltip-example" position="top" tooltipText={articlesText}>
          <span>{displayText}</span>
        </Tooltip>
      ) : (
        displayText
      );

      // return displayText;
    } else if (header === 'commodities') {
      const articlesText = item[header];
      const showTooltip = articlesText?.length > MAX_ARTICLES_LENGTH;
      const displayText = showTooltip
        ? `${articlesText.slice(0, MAX_ARTICLES_LENGTH)}...`
        : articlesText;
      return showTooltip ? (
        <Tooltip describedById="tooltip-example" position="top" tooltipText={articlesText}>
          <span>{displayText}</span>
        </Tooltip>
      ) : (
        displayText
      );
    } else if (header === 'Source') {
      return item[header] === false ? 'Manual' : 'API';
    } else {
      return item[header];
    }
  };
  const searchfunc = (e: any) => {
    console.log(e.target.value);
    props?.onSearch(e);
  };
  const onTableClick = (item: any, index: any) => {
    if (isLeftSide) {
      if (highlightedRow === index) {
        setHighlightedRow('');
        props?.onShowDetails(false);
      } else {
        setHighlightedRow(index);
        props?.onShowDetails(true, item.DDS_Ref_Num);
      }
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedItems = items.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);

  return (
    <>
      <div style={{ width: '100%' }}></div>
      <div>
        <div style={{ marginTop: '20px', overflowY: 'scroll', maxHeight: '400px' }}>
          <Table className="table">
            <TableHeader sticky>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{capitalizeHeader(header)}</th>
                ))}
              </tr>
            </TableHeader>

            <TableBody striped>
              {paginatedItems.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => onTableClick(item, index)}
                  className={
                    highlightedRow === index || item === selectedRow ? 'highlighted-row' : ''
                  }
                >
                  {headers.map((header, idx) => (
                    <td key={idx}>{renderTableDetails(header, item)}</td>
                  ))}
                </tr>
              ))}
            </TableBody>
          </Table>
        </div>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            items={items}
          />
        )}
      </div>

      <div style={{ position: 'absolute' }}>
        {modalOpen && modalDetails && (
          <ModalData
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            modalDetails={modalDetails}
          />
          // <Modal
          //   visible={modalOpen}
          //   handleCloseBtn={() => {
          //     setModalOpen(!modalOpen);
          //   }}
          // >
          //   <Theatre
          //     aria-label="Header for a modal"
          //     className="prompt-override"
          //     header={<ModalHeader ariaCloseTxt="Close button text" title="CRA Details" />}
          //   >
          //     <p>
          //       <strong>CRA:</strong> {modalDetails.CRA.isPassed ? 'Passed' : 'Failed'}
          //     </p>
          //     <p>
          //       <strong>Country:</strong> {modalDetails.Country}
          //     </p>
          //     <p>
          //       <strong>Commodity:</strong> {modalDetails.Commodities}
          //     </p>
          //     <p>
          //       <strong>Date Of Assessment:</strong>{' '}
          //       {new Date(modalDetails.CRA.assessmentDate).toLocaleDateString()}
          //     </p>
          //     <p>
          //       <strong>Topics and Sub-Topics:</strong>
          //     </p>
          //     <ul>
          //       {modalDetails.CRA.detail.map((detail, index) => (
          //         <li key={index}>
          //           <strong>{detail.topic}:</strong>
          //           <ul style={{ marginLeft: '30px' }}>
          //             {detail.subTopic.map((sub, idx) => (
          //               <li key={idx}>{sub}</li>
          //             ))}
          //           </ul>
          //         </li>
          //       ))}
          //     </ul>
          //   </Theatre>
          // </Modal>
        )}
      </div>
    </>
  );
};

export default TableLayout;
