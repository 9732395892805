import React, { useState } from 'react';
import chevronRightSmall from '@ingka/ssr-icon/paths/chevron-right-small';
import chevronLeftSmall from '@ingka/ssr-icon/paths/chevron-left-small';
import SSRIcon from '@ingka/ssr-icon';

const Pagination = (props: any) => {
  const { items, currentPage, handlePageChange, totalPages } = props;
  const ITEMS_PER_PAGE = 7;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
      <div>
        Showing {(currentPage - 1) * ITEMS_PER_PAGE + 1}-
        {Math.min(currentPage * ITEMS_PER_PAGE, items?.length)} of {items?.length}
      </div>
      <div style={{}}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          <SSRIcon paths={chevronLeftSmall} /> Previous ;
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            disabled={currentPage !== index + 1}
            className="pagination-number"
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage * ITEMS_PER_PAGE >= items?.length}
          className="pagination-button"
        >
          Next <SSRIcon paths={chevronRightSmall} />
        </button>
      </div>
    </div>
  );
};
export default Pagination;
