import React, { useState } from 'react';
import Search from '@ingka/search';

const GlobalSearch = ({ onSearch }) => {
  const [querys, setQuery] = useState('');

  const handleSearch = () => {
    onSearch(querys);
  };

  return (
    <div>
      <Search
        ariaLabel="Search for product categories, product names, etc."
        clearBtnText="Clear search field"
        id="search"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search"
        submitBtnText="Submit"
        onSearch={handleSearch}
        value={querys}
      />
    </div>
  );
};

export default GlobalSearch;
