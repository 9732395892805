import { appConfig } from 'appConfig';
import { SAVE_FOR_LATER_BUTTON_ACTION } from './types';
import { callAPI } from 'Action';

export const getSaveForLaterAction =
  (value: any, savedForLater: any = undefined) =>
  async (dispatch: any) => {
    let url = appConfig.baseUrl + 'FEDSAUpdateStatus';
    let reqObj = value;
    if (typeof savedForLater != 'object') {
      url += `?Status=${savedForLater}`;
    } else {
      return;
    }
    const options = {
      url,
      method: 'POST',
      actionType: SAVE_FOR_LATER_BUTTON_ACTION,
      dispatch,
      reqObj,
    };
    let saveForLaterAction = await callAPI(options);
    if (saveForLaterAction) {
      dispatch({
        type: SAVE_FOR_LATER_BUTTON_ACTION,
        payload: saveForLaterAction.data,
      });
    }
    return saveForLaterAction;
  };
