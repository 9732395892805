import { callAPI } from 'Action';
import { FILE_UPLOAD, FILE_UPLOAD_SUCESSFULLY } from './types';
import { appConfig } from 'appConfig';
import { UPLOAD_URL } from 'Action/PathConstants';

export const fileUpload = (value: any) => async (dispatch: any) => {
  let url = appConfig.baseUrl + UPLOAD_URL;
  let reqObj = value;

  const options = {
    url,
    actionType: FILE_UPLOAD,
    method: 'POST',
    reqObj,
    dispatch,
  };
  let data = await callAPI(options);
  if (data) {
    dispatch({
      type: FILE_UPLOAD_SUCESSFULLY,
      payload: data,
    });
  }
  return data;
};
export const resetFileUpload = () => (dispatch: any) => {
  dispatch({
    type: 'RESET_ERROR',
  });
  dispatch({ type: FILE_UPLOAD, payload: null });
  dispatch({ type: FILE_UPLOAD_SUCESSFULLY, payload: null });
};
