import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import React, { useState, useEffect } from 'react';
import { getRiskDetails, getPendingApprovalList } from 'Action/pendingApprovalAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { getFEDSAReferenceNumber } from 'utils/common';
const SubmitRegistery = ({ querys, setQuery, tabChange }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { pendingApprovalList, riskDetailList, submittedToEuList } = useSelector(
    (state: RootState) => state.pendingApprovalDetails
  );
  const [ddsReference, setDDSReference] = useState<any>();
  const [showDetailsData, setShowDetailsData] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const [riskDetailsById, setRiskDetailsById] = useState<any>([]);

  const mapping = {
    isEntryFromAPI: 'Source',
    articles: 'Articles',
    supplierName: 'Supplier Name',
    // m3ArticleNumber:'M3 Article number',
    commodities: 'Commodities',
    fedsa_RefNum: 'DDS_Ref_Num',
    receivedOn: 'DDS Received On',
    fedsaStatus: 'Current status',
    pdf: 'PDF',
  };
  const riskDetailMapping = {
    commodities: 'Commodities',
    country: 'Country',
    productionPlaceCount: 'Production Place',
    cra: 'CRA',
    gla: 'GLA',
  };
  {
    useEffect(() => {
      if (querys.length === 0) {
        let items = mappedItems(submittedToEuList, mapping);
        setListData(items);
      }
    }, [submittedToEuList]);

    const mappedItems = (itemList, mappingKey) => {
      return itemList?.map((item) => {
        return Object.keys(mappingKey).reduce((acc, key) => {
          acc[mappingKey[key]] = item[key] !== undefined ? item[key] : null; // Set to null if the key does not exist
          return acc;
        }, {});
      });
    };

    useEffect(() => {
      let items = mappedItems(riskDetailList, riskDetailMapping);
      setRiskDetailsById(items);
    }, [riskDetailList]);
    const onShowDetails = (value, fedsa_RefNum) => {
      if (fedsa_RefNum) {
        setDDSReference(fedsa_RefNum);
        const dds_id = getFEDSAReferenceNumber(submittedToEuList, fedsa_RefNum);
        dispatch(getRiskDetails(dds_id));
      }
      setShowDetailsData(value);
    };

    const withDraw = (e: any, button: any, index: any) => {
      console.log(e, button, index);
    };

    const buttonData = [
      { label: 'Withdraw DDS', onClick: withDraw },
      // Add more button configurations as needed
    ];
    return (
      <div className="details-container">
        <div className="table-container">
          <div className="table-list-view">
            {submittedToEuList && listData?.length > 0 && (
              <TableLayout
                items={listData}
                isLeftSide={true}
                onShowDetails={onShowDetails}
                pendingApprovalData={submittedToEuList}
              />
            )}
          </div>
        </div>
        {showDetailsData && riskDetailList?.length > 0 && riskDetailsById?.length > 0 && (
          <div className="detail-list">
            <DetailsLayout
              data={riskDetailsById}
              buttonData={buttonData}
              isCheckBoxDisable={true}
              isTextAreaDisbale={true}
              isDropdown={false}
            />
          </div>
        )}
      </div>
    );
  }
};

export default SubmitRegistery;
