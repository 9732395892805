import { getTemplatedAppNameOrDefault } from 'utils/getTemplatedAppNameOrDefault';

declare var window: any;

window.env = {
  CUSTOMER_BACKEND_ENDPOINT: `https://gateway.fedsa-nonprod.np.apaas.inter.ikea.net/dev/fedsa-api-customer-management/v1/customer`,
  CLIENT_ID: '12d7a0d4-2707-4897-a71b-8662356bfdc1',
  TENANT_ID: 'a33c6ac4-a52e-45c5-af07-b972df9bd004',
  SCOPE: `api://ebdc9487-1e0d-4d6e-8e0f-2e4dc6234773/API.Read,api://ebdc9487-1e0d-4d6e-8e0f-2e4dc6234773/API.Write,openid`,
  INSIGHTS_KEY: 'ad447ed3-990a-4fab-bbde-8d6bd4e07875',
};

export const appConfig = {
  appName: getTemplatedAppNameOrDefault('SWD Reference Frontend SPA'),
  baseUrl: `https://gateway.fedsa-nonprod.np.apaas.inter.ikea.net/dev/fedsa-dp-customer-management/v1/`,
  manualUrl: `https://gateway.fedsa-nonprod.np.apaas.inter.ikea.net/dev/fedsa-api-customer-management/v1/`,
  customerEndpoint:
    window?.env?.CUSTOMER_BACKEND_ENDPOINT || process.env.REACT_APP_CUSTOMER_BACKEND_ENDPOINT,
  appClientId: window?.env?.CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
  appTenantId: window?.env?.TENANT_ID || process.env.REACT_APP_TENANT_ID,
  appScope: window?.env?.SCOPE || process.env.REACT_APP_SCOPE,
  appInsightKey: window?.env?.INSIGHTS_KEY || process.env.REACT_APP_INSIGHTS_KEY,
  version: 'v0.22',
};

if (!Object.values(appConfig).every((x) => x !== null && x !== '' && x !== undefined)) {
  throw Error(`Missing value for env variables in env-config`);
}
