import React, { useState, useEffect } from 'react';
import './saveForLater.scss';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';
import { getRiskDetails, getPendingApprovalList } from 'Action/pendingApprovalAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { getFEDSAReferenceNumber } from 'utils/common';
import { getEudrSubmissionResult } from 'Action/eudrSubmissionAction';
import Toast from '@ingka/toast';

const SaveForLater = ({ querys, setQuery, tabChange, submittedToEUTabRef }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { pendingApprovalList, riskDetailList, saveForLaterList } = useSelector(
    (state: RootState) => state.pendingApprovalDetails
  );
  // const selectedSaveForLaterData = useSelector(state => state.saveForLaterList);
  const [ddsReference, setDDSReference] = useState<any>();
  const [showDetailsData, setShowDetailsData] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const [riskDetailsById, setRiskDetailsById] = useState<any>([]);
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [toastMessage, setToastMessage] = useState('');
  const [comment, setComment] = useState('');
  const mapping = {
    isEntryFromAPI: 'Source',
    articles: 'Articles',
    supplierName: 'Supplier Name',
    // m3ArticleNumber:'M3 Article number',
    commodities: 'Commodities',
    fedsa_RefNum: 'DDS_Ref_Num',
    receivedOn: 'DDS Received On',
    fedsaStatus: 'Current status',
    pdf: 'PDF',
  };
  const riskDetailMapping = {
    commodities: 'Commodities',
    country: 'Country',
    productionPlaceCount: 'Production Place',
    cra: 'CRA',
    gla: 'GLA',
  };

  useEffect(() => {
    if (querys.length === 0) {
      let items = mappedItems(saveForLaterList, mapping);
      setListData(items);
    }
  }, [saveForLaterList]);

  useEffect(() => {
    let items = mappedItems(riskDetailList, riskDetailMapping);
    setRiskDetailsById(items);
  }, [riskDetailList]);
  const mappedItems = (itemList, mappingKey) => {
    return itemList?.map((item) => {
      return Object.keys(mappingKey).reduce((acc, key) => {
        acc[mappingKey[key]] = item[key] !== undefined ? item[key] : null; // Set to null if the key does not exist
        return acc;
      }, {});
    });
  };

  const onClose = () => {
    setToastOpen(false);
  };
  const submit = async () => {
    if (ddsReference) {
      try {
        const response = await dispatch(
          getEudrSubmissionResult({ id: ddsReference, description: comment })
        );

        if (response && response?.request?.status === 200) {
          setToastOpen(true);
          setToastMessage('Submission Success');
          setTimeout(() => {
            setToastOpen(false);
            if (submittedToEUTabRef.current) {
              submittedToEUTabRef.current.click();
            }
          }, 2000);
        } else {
          setToastOpen(true);
          setToastMessage('Submission failed');
          return;
        }
      } catch (error) {
        setToastOpen(true);
        setToastMessage('Submission failed');
      }
    }
  };

  const invalidate = (e: any, button: any, index: any) => {
    console.log(e, button, index);
  };
  const onSearch = (e: any) => {
    console.log(e);
  };
  const onShowDetails = (value, fedsa_RefNum) => {
    if (fedsa_RefNum) {
      setDDSReference(fedsa_RefNum);
      const dds_id = getFEDSAReferenceNumber(saveForLaterList, fedsa_RefNum);
      dispatch(getRiskDetails(dds_id));
    }
    setShowDetailsData(value);
  };
  const buttonData = [
    { label: 'Submit', onClick: submit },
    { label: 'Invalidate', onClick: invalidate },
    // Add more button configurations as needed
  ];
  return (
    <div className="details-container">
      <div className="table-container">
        <div className="table-list-view">
          {saveForLaterList && listData?.length > 0 && (
            <TableLayout
              items={listData}
              isLeftSide={true}
              onSearch={onSearch}
              onShowDetails={onShowDetails}
              pendingApprovalData={saveForLaterList}
              tabChange={tabChange}
            />
          )}
        </div>
      </div>
      {showDetailsData && riskDetailList?.length > 0 && riskDetailsById?.length > 0 && (
        <div className="detail-list">
          <DetailsLayout
            data={riskDetailsById}
            buttonData={buttonData}
            isCheckBoxDisable={false}
            isTextAreaDisbale={false}
            isDropdown={true}
            ddsReference={ddsReference}
            comment={comment}
            setComment={setComment}
          />
        </div>
      )}
      {toastOpen && (
        <Toast
          text={<strong>{toastMessage}</strong>}
          isOpen={toastOpen}
          actionClick={() => {}} // required if there is an action label supplied
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
        />
      )}
    </div>
  );
};

export default SaveForLater;
