import { appConfig } from 'appConfig';
import { EU_SUBMITION_BUTTON } from './types';
import { callAPI } from 'Action';

export const getEudrSubmissionResult = (value: any) => async (dispatch) => {
  let url = appConfig.baseUrl + 'FEDSAEUDDSInfoSubmit';
  let reqObj = value;

  const options = {
    url,
    method: 'POST',
    actionType: EU_SUBMITION_BUTTON,
    dispatch,
    reqObj,
  };
  let data = await callAPI(options);
  if (data) {
    dispatch({
      type: EU_SUBMITION_BUTTON,
      payload: data,
    });
  }
  return data;
};
