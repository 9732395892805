import React, { useEffect, useState } from 'react';
import './pendingApproval.scss';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { getPendingApprovalList, getRiskDetails } from 'Action/pendingApprovalAction';
import { getFEDSAReferenceNumber } from 'utils/common';
import { getEudrSubmissionResult } from 'Action/eudrSubmissionAction';
import Toast from '@ingka/toast';
import { getSaveForLaterAction } from 'Action/saveForLaterAction';

const PendingApproval = ({ querys, setQuery, tabChange, handleTabChange, submittedToEUTabRef }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { pendingApprovalList, riskDetailList, saveForLaterList } = useSelector(
    (state: RootState) => state.pendingApprovalDetails
  );
  const [showDetailsData, setShowDetailsData] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [riskDetailsById, setRiskDetailsById] = useState<any>([]);
  const [ddsReference, setDDSReference] = useState<any>();
  const [submittedData, setSubmittedData] = useState(null);
  const [savedForLater, setSavedForLater] = useState('SaveForLater');
  const [selectedPendingData, setSelectedPendingData] = useState(pendingApprovalList);
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [toastMessage, setToastMessage] = useState('');
  const [comment, setComment] = useState('');
  const mapping = {
    isEntryFromAPI: 'Source',
    articles: 'Articles',
    supplierName: 'Supplier Name',
    // m3ArticleNumber:'M3 Article number',
    commodities: 'Commodities',
    fedsa_RefNum: 'DDS_Ref_Num',
    receivedOn: 'DDS Received On',
    fedsaStatus: 'Current status',
    pdf: 'PDF',
  };
  const riskDetailMapping = {
    commodities: 'Commodities',
    country: 'Country',
    productionPlaceCount: 'Production Place',
    cra: 'CRA',
    gla: 'GLA',
  };

  // useEffect(() => {
  //   dispatch(getPendingApprovalList(tabChange));
  // }, [tabChange]);

  useEffect(() => {
    if (querys.length === 0) {
      let items = mappedItems(pendingApprovalList, mapping);
      setListData(items);
    } else {
      let items = mappedItems(selectedPendingData, mapping);
      setListData(items);
    }
  }, [selectedPendingData]);
  useEffect(() => {
    let items = mappedItems(riskDetailList, riskDetailMapping);
    setRiskDetailsById(items);
  }, [riskDetailList]);

  useEffect(() => {
    if (pendingApprovalList) {
      const filtered = pendingApprovalList.filter(
        (item) =>
          item.supplierName?.toLowerCase().includes(querys.toLowerCase()) ||
          item.commodities?.toLowerCase().includes(querys.toLowerCase()) ||
          item.fedsa_RefNum?.toLowerCase().includes(querys.toLowerCase())
      );

      setSelectedPendingData(filtered);
    }
  }, [querys, pendingApprovalList]);

  const mappedItems = (itemList, mappingKey) => {
    return itemList?.map((item) => {
      return Object.keys(mappingKey).reduce((acc, key) => {
        acc[mappingKey[key]] = item[key] !== undefined ? item[key] : null; // Set to null if the key does not exist
        return acc;
      }, {});
    });
  };

  const saveForLater = async (ddsReference) => {
    if (ddsReference) {
      setDDSReference(ddsReference);
      setSavedForLater('SaveForLater');
      const dds_id = getFEDSAReferenceNumber(saveForLaterList, ddsReference);
      dispatch(getRiskDetails(dds_id));

      try {
        const response = await dispatch(
          getSaveForLaterAction({ id: ddsReference, description: comment }, 'SaveForLater')
        );
        if (response && response?.request?.status === 200) {
          setToastOpen(true);
          setToastMessage('Saved for later');
          setTimeout(() => {
            setToastOpen(false);
            window.location.reload();
          }, 2000);
        } else {
          setToastOpen(true);
          setToastMessage('Failed to save for later');
          return;
        }
      } catch (error) {
        setToastOpen(true);
        setToastMessage('error');
      }
    }
  };
  const onClose = () => {
    setToastOpen(false);
  };
  const submit = async () => {
    if (ddsReference) {
      try {
        const response = await dispatch(getEudrSubmissionResult({ id: ddsReference }));

        if (response && response?.request?.status === 200) {
          setToastOpen(true);
          setToastMessage('Submission Success');
          setTimeout(() => {
            setToastOpen(false);
            if (submittedToEUTabRef.current) {
              submittedToEUTabRef.current.click();
            }
          }, 2000);
        } else {
          setToastOpen(true);
          setToastMessage('Submission failed');
          return;
        }
      } catch (error) {
        setToastOpen(true);
        setToastMessage('Submission failed');
      }
    }
  };

  const invalidate = (e: any, button: any, index: any) => {
    setSavedForLater('inValidate');
    dispatch(getSaveForLaterAction({ id: ddsReference }, savedForLater));
    return;
  };
  const buttonData = [
    { label: 'Save for later', onClick: () => saveForLater(ddsReference) },
    { label: 'Submit', onClick: submit },
    { label: 'Invalidate', onClick: invalidate },
  ];

  const onShowDetails = (value, fedsa_RefNum) => {
    if (fedsa_RefNum) {
      setDDSReference(fedsa_RefNum);
      const dds_id = getFEDSAReferenceNumber(pendingApprovalList, fedsa_RefNum);
      dispatch(getRiskDetails(dds_id));
    }
    setShowDetailsData(value);
  };
  const onSearch = (searchQuery) => {
    setQuery(searchQuery);
  };
  return (
    <div className="details-container">
      <div className="table-container">
        <div className="table-list-view">
          {pendingApprovalList && listData?.length > 0 && (
            <div className="tablelayout">
              <TableLayout
                items={listData}
                isLeftSide={true}
                onShowDetails={onShowDetails}
                pendingApprovalData={selectedPendingData}
                onSearch={onSearch}
                tabChange={tabChange}
              />
            </div>
          )}
        </div>
      </div>
      {showDetailsData && riskDetailList?.length > 0 && riskDetailsById?.length > 0 && (
        <div className="detail-list">
          <DetailsLayout
            data={riskDetailsById}
            buttonData={buttonData}
            isCheckBoxDisable={false}
            isTextAreaDisbale={false}
            isDropdown={true}
            ddsReference={ddsReference}
            pendingApprovalData={pendingApprovalList}
            comment={comment}
            setComment={setComment}
            // ddsVerificationNumber={ddsVerificationNumber}
          />
        </div>
      )}
      {toastOpen && (
        <Toast
          text={<strong>{toastMessage}</strong>}
          isOpen={toastOpen}
          actionClick={() => {}}
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
        />
      )}
    </div>
  );
};

export default PendingApproval;
