import React from 'react';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import './modalData.scss';

export const ModalData = (props: any) => {
  return (
    <>
      <Modal
        visible={props.modalOpen}
        handleCloseBtn={() => {
          props.setModalOpen(!props.modalOpen);
        }}
      >
        <Theatre
          aria-label="Header for a modal"
          className="prompt-override"
          header={<ModalHeader ariaCloseTxt="Close button text" title="CRA Details" />}
        >
          <p>
            <strong>CRA:</strong> {props.modalDetails.CRA.isPassed ? 'Passed' : 'Failed'}
          </p>
          <p>
            <strong>Country:</strong> {props.modalDetails.Country}
          </p>
          <p>
            <strong>Commodity:</strong> {props.modalDetails.Commodities}
          </p>
          <p>
            <strong>Date Of Assessment:</strong>{' '}
            {new Date(props.modalDetails.CRA.assessmentDate).toLocaleDateString()}
          </p>
          <p>
            <strong>Topics and Sub-Topics:</strong>
          </p>
          <ul>
            {props.modalDetails.CRA.detail.map((detail, index) => (
              <li key={index}>
                <strong>{detail.topic}:</strong>
                <ul style={{ marginLeft: '30px' }}>
                  {detail.subTopic.map((sub, idx) => (
                    <li key={idx}>{sub}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Theatre>
      </Modal>
    </>
  );
};
