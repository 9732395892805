import {
  PENDING_APPROVAL_LIST,
  RISK_DETAILS,
  SAVE_FOR_LATER_LIST,
  SUBMITTED_TO_EU_LIST,
} from 'Action/types';

const initialState = {
  pendingApprovalList: null,
  saveForLaterList: null,
  submittedToEuList: null,
};

export default (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case PENDING_APPROVAL_LIST:
      return {
        ...state,
        pendingApprovalList: action.payload,
      };
    case RISK_DETAILS:
      return {
        ...state,
        riskDetailList: action.payload,
      };
    case SAVE_FOR_LATER_LIST:
      return {
        ...state,
        saveForLaterList: action.payload,
      };
    case SUBMITTED_TO_EU_LIST:
      return {
        ...state,
        submittedToEuList: action.payload,
      };
    default:
      return state;
  }
};
